.input-group-icon {
  position: relative;
  .input-box {
    padding-left: 2.8rem;
    vertical-align: middle;
  }
  .input-box-icon {
    position: absolute;
    top: 50%;
    left: 1rem;
    color: #212529;
    transform: translateY(-50%);
  }
}
