.card-gradient-hover {
  // background: $white;
  transition: 0.4s;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: $border-radius;
    background: linear-gradient(45deg, $primary, shift-color($primary, 15%));
    transition: 0.4s;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
  }

  &:hover {
    transform: translateY(-5px);
    color: $white !important;
  }
}

@each $state, $value in $theme-colors {
  .#{$state}-hover-card {
    transition: background 0.2s ease;
    &:hover {
      background: linear-gradient(45deg, $value, shift-color($value, 15%));

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p,
      a {
        color: $white !important;
      }
    }
  }
}
