.banner-shape1,
.banner-shape2,
.banner-shape3,
.banner-shape4,
.banner-shape5,
.banner-shape6 {
  position: absolute;
}

.banner-shape1 {
  left: 2%;
  top: 12%;
}

.banner-shape2 {
  left: 30%;
  top: 9%;
}

.banner-shape3 {
  bottom: 7%;
  left: 2%;
}

.banner-shape4 {
  bottom: 12%;
  left: 29%;
}

.banner-shape5 {
  right: 2%;
  top: 15%;
}

.banner-shape6 {
  right: 0;
  bottom: 45px;
}
