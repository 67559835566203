/* prettier-ignore */

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
// scss-docs-start container-max-widths
$container-max-widths: () !default;
$container-max-widths: map-merge(
  (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1480px,
  ),
  $container-max-widths
);

$grid-breakpoints: () !default;
$grid-breakpoints: map-merge(
  (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1540px,
  ),
  $grid-breakpoints
);

//*-----------------------------------------------
//|   Gutter
//-----------------------------------------------*/
$grid-gutter-width: 2rem !default;

//*-----------------------------------------------
//|   Color System
//-----------------------------------------------*/

$white: #fff !default;
$gray-100: #f9f9f9 !default;
$gray-200: #f5f8ff !default;
$gray-300: #e1e1e1 !default;
$gray-400: #bebebe !default;
$gray-500: #949494 !default;
$gray-600: #7f7f7f !default;
$gray-700: #6a6a6a !default;
$gray-800: #555555 !default;
$gray-900: #404040 !default;
$gray-1000: #2b2b2b !default;
$gray-1100: #1f1f1f !default;
$black: #000 !default;

$grays: () !default;
$grays: map-merge(
  (
    "black": $black,
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900,
    "1000": $gray-1000,
    "white": $white,
  ),
  $grays
);

//*-----------------------------------------------
//|   Solid Colors
//-----------------------------------------------*/
$blue: #387adf !default;
$indigo: #727cf5 !default;
$purple: #6b5eae !default;
$pink: #ff679b !default;
$red: #ed1d61 !default;
$orange: #fba834 !default;
$yellow: #fce850 !default;
$green: #00d27a !default;
$teal: #02a8b5 !default;
$cyan: #50c4ed !default;

//*-----------------------------------------------
//|   Theme Colors
//-----------------------------------------------*/
$primary: $red !default;
$secondary: $gray-900 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $orange !default;
$danger: $pink !default;
$light: $gray-100 !default;
$dark: $gray-1100 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);
$theme-color-interval: 8% !default;

$theme-colors-soft: () !default;
$theme-colors-soft: map-merge(
  (
    "primary": tint-color($primary, 88%),
    "secondary": tint-color($secondary, 88%),
    "success": tint-color($success, 88%),
    "info": tint-color($info, 88%),
    "warning": tint-color($warning, 88%),
    "danger": tint-color($danger, 88%),
    "light": tint-color($light, 88%),
    "dark": tint-color($dark, 88%),
  ),
  $theme-colors-soft
);

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: $gray-1000 !default;

// Min contrast ratio
$min-contrast-ratio: 2 !default;

//*-----------------------------------------------
//|   Brand colors
//-----------------------------------------------*/
$linkedin: #0077b5 !default;
$facebook: #3c5a99 !default;
$twitter: #1da1f2 !default;
$google-plus: #dd4b39 !default;
$github: #333 !default;
$youtube: #ff0000 !default;

$brand-colors: () !default;
$brand-colors: map-merge(
  (
    "facebook": $facebook,
    "google-plus": $google-plus,
    "twitter": $twitter,
    "linkedin": $linkedin,
    "youtube": $youtube,
    "github": $github,
  ),
  $brand-colors
);

//*-----------------------------------------------
//|   Border
//-----------------------------------------------*/
$border-color: $gray-300 !default;
$border-width: 1px !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows: true !default;
$enable-gradients: false !default;
$enable-negative-margins: true !default;

//*-----------------------------------------------
//|   Shadow
//-----------------------------------------------*/

$box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2) !default;
$box-shadow-sm: 5px 10px 30px #f6f4f4 !default;
$box-shadow-lg: 0 1rem 4rem rgba($black, 0.175) !default;

//*-----------------------------------------------
//|   Spacing
//----------------------------------------------

$spacer: 1rem !default;
$spacers: () !default;

$spacers: map-merge(
  (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.8,
    5: $spacer * 3,
    6: $spacer * 4,
    7: $spacer * 5,
    8: $spacer * 7.5,
    9: $spacer * 10,
    10: $spacer * 12.5,
    11: $spacer * 15,
  ),
  $spacers
);

$position-values: (
  0: 0,
  50: 50%,
  100: 100%,
);

//*-----------------------------------------------
//|   Body
//-----------------------------------------------*/
$body-bg: $white !default;
$body-color: $secondary !default;

//*-----------------------------------------------
//|   Link
//-----------------------------------------------*/
// Style anchor elements.

$link-color: $primary !default;
$link-decoration: none !default;
$link-hover-decoration: underline !default;
$link-hover-color: tint-color($primary, 50%);

//*-----------------------------------------------
//|   Components
//-----------------------------------------------*/
$border-radius: 0.25rem !default;
$border-radius-sm: 0.15rem !default;
$border-radius-lg: 1.25rem !default;
$border-radius-pill: 50rem !default;

//*-----------------------------------------------
//|   Fonts Families
//-----------------------------------------------*/
$font-family-sans-serif: "Jost", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-cursive: "DM Serif Display", cursive !default;
$font-family-monospace: "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
  "Segoe UI Symbol" !default;

//*-----------------------------------------------
//|   Fonts
//-----------------------------------------------*/
$type-scale: 1.2;
$font-size-base: 0.875rem !default;
$font-sizes: () !default;
$font-sizes: map-merge(
  (
    "-2": 1 / pow($type-scale, 2) * $font-size-base,
    "-1": 1 / $type-scale * $font-size-base,
    0: $font-size-base,
    1: pow($type-scale, 1) * $font-size-base,
    2: pow($type-scale, 2) * $font-size-base,
    3: pow($type-scale, 3) * $font-size-base,
    4: pow($type-scale, 4) * $font-size-base,
    5: pow($type-scale, 5) * $font-size-base,
    6: pow($type-scale, 6) * $font-size-base,
    7: pow($type-scale, 7) * $font-size-base,
    8: pow($type-scale, 8) * $font-size-base,
    9: pow($type-scale, 9) * $font-size-base,
    10: pow($type-scale, 10) * $font-size-base,
  ),
  $font-sizes
);

$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.2 !default;
$font-weight-thin: 100 !default;
$font-weight-lighter: 200 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: 800 !default;
$font-weight-black: 900 !default;

$h1-font-size: map_get($font-sizes, 5) !default;
$h2-font-size: map_get($font-sizes, 4) !default;
$h3-font-size: map_get($font-sizes, 3) !default;
$h4-font-size: map_get($font-sizes, 2) !default;
$h5-font-size: map_get($font-sizes, 1) !default;
$h6-font-size: map_get($font-sizes, "-1") !default;

$headings-font-family: $font-family-sans-serif !default;
$headings-font-weight: $font-weight-bold !default;
$headings-color: $black !default;

// scss-docs-start display-headings
$display-font-sizes: () !default;
$display-font-sizes: map-merge(
  (
    1: map_get($font-sizes, 8),
    2: map_get($font-sizes, 7),
    3: map_get($font-sizes, 6),
    4: map_get($font-sizes, 5),
    5: map_get($font-sizes, 4),
    6: map_get($font-sizes, 3),
  ),
  $display-font-sizes
);

$line-height-base: 1.5;
$line-height-sm: 1.15;
$line-height-lg: 2;

$display-font-weight: $font-weight-black !default;
$display-line-height: 1 !default;
// scss-docs-end display-headings

$lead-font-size: $font-size-lg !default;
$lead-font-weight: $font-weight-normal !default;

$small-font-size: 75% !default;

$text-muted: $gray-500 !default;

$blockquote-font-size: $font-size-lg !default;

// Allows for customizing button radius independently from global border radius

//*-----------------------------------------------
//|   Buttons
//-----------------------------------------------*/
$input-btn-padding-y: 0.65rem !default;
$input-btn-padding-x: 1.8rem !default;
$input-btn-font-size: 1rem !default;

$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-x-sm: 1.2rem !default;
$input-btn-font-size-sm: $font-size-base;

$input-btn-padding-y-lg: 0.85rem !default;
$input-btn-padding-x-lg: 2.2rem !default;

$input-btn-font-size-lg: $font-size-lg !default;
$btn-font-weight: $font-weight-medium;
$btn-focus-width: 0 !default;

$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius;
$btn-border-radius-lg: $border-radius;

$btn-reveal-hover-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba($black, 0.07),
  0 1px 2px 0 rgba($black, 0.08) !default;

//*-----------------------------------------------
//|  Forms
//-----------------------------------------------*/
$input-bg: $gray-100 !default;
$input-border-color: $gray-300 !default;
$input-placeholder-color: $gray-600 !important;
$input-box-shadow: 0 0 0 transparent;
$form-label-font-size: map-get($font-sizes, 1);

//*-----------------------------------------------
//|   Navigation
//-----------------------------------------------*/
$navbar-font-size: $font-size-base * 0.8 !default;
$navbar-light-color: $gray-900 !default;
$navbar-light-hover-color: $primary !default;
$navbar-light-active-color: $primary !default;
$navbar-light-disabled-color: rgba($black, 0.25) !default;

$navbar-dark-color: rgba($white, 0.7) !default;
$navbar-dark-hover-color: rgba($white, 0.9) !default;

$navbar-padding-y: map_get($spacers, 3) !default;
// $navbar-padding-x: $spacer !default;
$top-nav-height: 4.3125rem !default;
$standard-nav-height: 3.5625rem !default;
$navbar-nav-link-padding-x: 1.2rem;
$nav-link-font-size: map-get($font-sizes, 1);
$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M0 6h30M0 14h30M0 22h30'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;

$nav-link-font-weight: $font-weight-medium;
$nav-link-color: $secondary;
$nav-link-hover-color: $primary;

// /*-----------------------------------------------
//|   Navbar Brand
//-----------------------------------------------*/
$navbar-brand-font-size: map_get($font-sizes, 3) !default;
$navbar-brand-font-weight: $font-weight-bolder !default;

// /*-----------------------------------------------
//|   Breadcrumb
//-----------------------------------------------*/
$breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='currentColor'/%3E%3C/svg%3E");

//*-----------------------------------------------
//|   Viewport Heights & Widths
//----------------------------------------------

$viewport-heights: () !default;
$viewport-heights: map-merge(
  (
    25: 25vh,
    50: 50vh,
    75: 75vh,
    100: 100vh,
  ),
  $viewport-heights
);

$viewport-widths: () !default;
$viewport-widths: map-merge(
  (
    25: 25vw,
    50: 50vw,
    75: 75vw,
    100: 100vw,
  ),
  $viewport-widths
);

$sizes: () !default;
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
  ),
  $sizes
);

// /*-----------------------------------------------
//|   Spinner
//-----------------------------------------------*/
$hr-color: $border-color !default;
$hr-opacity: 1 !default;

//*-----------------------------------------------
//|   card
//-----------------------------------------------*/
// $card-border-radius:                2.5rem !default;
$card-cap-bg: tint-color($gray-500, 90%) !default;
$card-border-width: 0 !default;
$card-box-shadow: $box-shadow-sm !default;

//*-----------------------------------------------
//|   carousel
//-----------------------------------------------*/
$carousel-control-opacity: 0.9 !default;
$carousel-control-hover-opacity: 0.9 !default;
$carousel-control-color: $primary !default;

$carousel-indicator-active-bg: $primary !default;
$carousel-indicator-width: 6px !default;
$carousel-indicator-height: 6px !default;
$carousel-indicator-hit-area-height: 0 !default;
$carousel-control-bg-color: rgba($primary, 0.3) !important;

$carousel-control-icon-width: 1.8rem !default;
$carousel-control-prev-icon-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$carousel-control-color}' class='bi bi-arrow-left' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'/%3E%3C/svg%3E") !default;
$carousel-control-next-icon-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{$carousel-control-color}' class='bi bi-arrow-right' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z'/%3E%3C/svg%3E") !default;

// Navs
$nav-tabs-link-active-color: $orange !default;
$nav-tabs-link-active-border-color: transparent !default;
$nav-tabs-link-active-bg: rgba($orange, 0.15) !default;

// Accordion
$accordion-padding-y: 1.5rem !default;
$accordion-padding-x: 2.6rem !default;
$accordion-bg: $gray-100 !default;
$accordion-border-width: $border-width !default;
$accordion-border-color: rgba($white, 0.125) !default;
$accordion-border-radius: 0 !default;

$accordion-button-active-bg: $gray-100 !default;
$accordion-button-active-color: $dark !default;

$accordion-button-focus-border-color: $white !default;
$accordion-button-focus-box-shadow: $light !default;

$accordion-icon-width: 1.8rem !default;
$accordion-icon-active-color: $dark !default;

$accordion-button-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#EE4D47' class='bi bi-plus' viewBox='0 0 16 16'%3E%3Cpath d='M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z'/%3E%3C/svg%3E") !default;
$accordion-button-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#EE4D47' class='bi bi-dash' viewBox='0 0 16 16'%3E%3Cpath d='M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z'/%3E%3C/svg%3E") !default;

//*-----------------------------------------------
//|   Badge
//-----------------------------------------------*/

$badge-padding-y: 0.35em;
$badge-padding-x: 0.65em;

//*-----------------------------------------------
//|   Scrollbar
//-----------------------------------------------*/
$scrollbar-bg: $gray-200 !default;
$simplebar-bg: $scrollbar-bg !default;
