/*-----------------------------------------------
|   Icons group
-----------------------------------------------*/
.icon-group {
  display: flex;
  .icon-item:not(:last-child) {
    margin-right: map_get($spacers, 2);
  }
}

.icon-item {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: $transition-base;
  height: 2.25rem;
  width: 2.25rem;
  border: 0;
  font-size: $font-size-lg;
  color: $white;
  background-color: $black;
  @include hover-focus {
    background-color: $primary;
    color: $white;
  }

  &.icon-item-sm {
    height: 1.5rem;
    width: 1.5rem;
  }

  &.icon-item-lg {
    height: 3.5rem;
    width: 3.5rem;
  }
}

@each $state, $value in $theme-colors {
  .icon-item-soft-#{$state} {
    background-color: tint-color($value, 88%);
    color: $value;
  }
}

.fontawesome-i2svg-active {
  .icon-indicator {
    opacity: 1;
  }
}
